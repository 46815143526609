.primary_button {
    border-radius: 4px;
    /* padding: 4px 8px 4px 8px; */
    display: inline-block;
    cursor: pointer;
    background-color: gray;
    font-size: 12px;
    padding: 5px 5px 5px 5px;
    min-height: 10px;
    background-color: #3DA1F6;
    color: white;
}

.primary_button:hover {
    scale: 1.05;
}

.primary_button:focus {
    border: 2px solid #2475BB
}

.primary_button.active {
    background-color: #2475BB;
}

.dropdown_child {
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    align-items: center;
}

.parent_dropdown {
    position: relative;
}

.poupup_dropdown {
    position: absolute;
    z-index: 50;
    margin-top: 4px;
    min-width: 200px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #2475BB;
    padding: 4px;
}

.option_list_box_dropdown {
    overflow-y: auto;
    max-height: 384px;
    padding-right: 8px;
}

.option_list_dropdown {
    border-radius: 5px;
    font-size: 10;
    padding: 2px 4px 2px 4px;
    display: block;
    cursor: pointer;
    background-color: white;
    margin-top: 2px;
    border: 1px solid #2475BB;
    min-height: 25px;
    display: flex;
    align-items: center;
}

.option_list_dropdown:hover {
    background-color: #3DA1F6;
    color: white
}

.filter_dropdown {
    max-height: 25px;
}

.children_dropdown {
    color: #2475BB;
    background-color: white;
    font-size: 12px;
    border-radius: 3px;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    align-items: center;
    /* text-neutral-white mp-p4 py-[2px] px-[6px] rounded-[8px] flex items-center */
}

.parent_children_dropdown {
    display: flex;
    flex-wrap: wrap;
    gap: 2px 2px;
}