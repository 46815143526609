.RichEditor-root {
  background: #fff;
  margin-bottom: 500px;
  max-width: 100%;
}

.RichEditor-editor {
  cursor: text;
  font-size: 16.5px;
  color: #343434;
  line-height: 25px;
  letter-spacing: 0.02em;
  display: inline;
  font-weight: 400;
  max-width: 100%;
  margin-bottom: 500px;
  text-align: justify;
}

/* .RichEditor-editor h3 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 3vw;
  font-weight: 505;
  letter-spacing: -0.11vw;
  line-height: normal;
} */

.RichEditor-editor h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}
.RichEditor-editor h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}
.RichEditor-editor h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}
.RichEditor-editor h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}
.RichEditor-editor h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}
.RichEditor-editor h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1;
}

.RichEditor-editor div {
  margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
  /* .RichEditor-editor h3 {
    font-size: 20px;
    font-weight: 505;
    letter-spacing: -0.11px;
  } */
}

@media screen and (min-width: 1000px) {
  /* .RichEditor-editor h3 {
    font-size: 33px;
    font-weight: 505;
    letter-spacing: -0.11px;
  } */
}

.RichEditor-editor a {
  display: inline-block;
  margin-left: -41px;
  text-decoration: none;
  color: #2196f3;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  padding: 20px;
}

.RichEditor-editor .public-DraftStyleDefault-block {
  margin-bottom: 5px;
}

pre {
  white-space: normal;
  background-color: #0ca5ebff;
  color: #fff;
  border-radius: 15px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.RichEditor-editor .RichEditor-code-block {
  color: #fff;
  line-height: 20px;
}

.RichEditor-editor .RichEditor-center {
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  cursor: pointer;
  margin-right: 7px;
  margin-top: 7px;
  padding: 7px 14px 7px 14px;
  display: inline-block;

  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
  box-shadow: 0 0 6px #9d9d9d53;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: rgb(99, 99, 99);
  font-size: 9px;
}

.RichEditor-activeButton {
  color: white;
  background-color: #7abcff;
}

.textImage {
  display: block;
  margin: auto;
  max-width: 90%;
  max-height: 70vh;
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
}

.newPopup {
  min-width: 200px;
  height: 100%;
  max-height: 300px;
  padding-left: 5px;
  overflow-y: auto;
  overflow-x: auto;
}

.valueNewPopup {
  max-width: 200px;
  width: 100%;
  margin: 3px 0;
  word-break: break-word;
}

.valueNewPopup p {
  color: #0ca5eb;
  font-size: 0.9rem;
  cursor: default;
}

.valueNewPopup h3,
.valueNewPopup a {
  font-size: 0.9rem;
  font-weight: 500;
  margin: -7px 0 5px 0;
}
.valueNewPopup a {
  color: #0ca5eb;
}

.popup_textarea {
  background-color: #e5eefd;
  height: 100px;
  padding: 3px;
  overflow: auto;
}

.photo_popup {
  width: 90%;
}

.simplemap_scroll::-webkit-scrollbar,
.statistic_resume_box::-webkit-scrollbar,
.carousell::-webkit-scrollbar,
.popup_textarea::-webkit-scrollbar,
.newPopup::-webkit-scrollbar {
  width: 10px;
  z-index: 1;
  background-color: transparent;
}

.simplemap_scroll::-webkit-scrollbar-track,
.statistic_resume_box::-webkit-scrollbar-track,
.carousell::-webkit-scrollbar-track,
.popup_textarea::-webkit-scrollbar-track,
.newPopup::-webkit-scrollbar-track {
  background-color: transparent;
}

.simplemap_scroll::-webkit-scrollbar-thumb,
.statistic_resume_box::-webkit-scrollbar-thumb,
.carousell::-webkit-scrollbar-thumb,
.popup_textarea::-webkit-scrollbar-thumb,
.newPopup::-webkit-scrollbar-thumb {
  background-color: #0ca5eb;
  border: 3px solid transparent;
  background-clip: content-box;
}
