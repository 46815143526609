.map_marker_loading {
  -webkit-animation-direction: alternate;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-name: bounce;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 0.7s;
  animation-name: bounce;
  animation-iteration-count: infinite;
  position: absolute;
}

.map_marker_center {
  transform: translate(-50%, 0%);
}

@-webkit-keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 10%;
  }
}

@keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 10%;
  }
}
