body {
  font-family: "Figtree", sans-serif;
  overflow: auto;
  overflow-x: hidden;
  background-color: #e4ecf1ff;
}

a {
  text-decoration: none;
  color: #000000;
}

hr {
  background-color: transparent;
  border-top: 1px solid rgb(154, 154, 154);
  margin-top: 10px;
  margin-bottom: 10px;
}

.red {
  background-color: #e02c35;
  color: white;
}

.blue {
  background-color: #0ca5eb;
  color: white;
}

.background_white {
  background-color: #fff;
  color: #000;
  stroke: #000;
}

.button_white {
  background-color: #fff;
  color: #000;
  stroke: #000;
}

.button_white:hover {
  background-color: rgb(240, 246, 246);
}

.button_transparent {
  background-color: transparent;
  color: #000;
  stroke: #000;
}

.button_transparent:hover {
  background-color: rgb(240, 246, 246);
}

.background_light_grey {
  background-color: rgb(240, 246, 246);
  color: #000;
}

.background_red {
  background-color: #e02c35;
  color: white;
}

.background_red_bright {
  background-color: #f9dadc;
  color: #dc000b;
  border: 2px solid #dc000b;
}

.background_black {
  background-color: #000;
  color: #fff;
  stroke: #fff;
}

.background_green {
  background-color: rgb(105, 178, 37);
  color: #fff;
}

.button_green {
  background-color: rgb(105, 178, 37);
  color: #fff;
}

.button_green:hover {
  background-color: rgb(80, 136, 28);
}

.background_blue {
  background-color: #0ca5eb;
  color: #fff;
}

.background_grey {
  background-color: #dedede;
  color: #000;
}

.background_grey_light {
  background-color: #e8ecf1;
  color: #000;
}

.background_blue_light {
  background-color: #dbeafe;
  color: #000;
}

.background_dark_blue {
  background-color: #16598d;
  color: #fff;
}

.background_teal {
  background-color: rgb(229, 253, 235);
  color: #11217e;
}

.background_orange {
  background-color: #f38026;
  color: #fff;
}

.text_grey {
  color: #dedede;
}

.outline_black {
  border: 2px solid rgb(0, 0, 0);
}

.outline_grey {
  border: 2px solid #d5d7dbff;
}

.outline_blue {
  border: 2px solid #1654ccff;
}

.outline_red {
  border: 2px solid #9b2020;
}

.outline_green {
  border: 2px solid rgb(107, 175, 43);
}

.outline_white {
  border: 2px solid #fff;
}

#outline_blue {
  background-color: #e5eefdff;
  border: 2px solid #1654ccff;
}

#outline_grey {
  background-color: #fff;
  border: 2px solid rgb(154, 154, 154);
  color: #000;
}

.outline_transparent {
  border: 0px solid transparent;
}

#background_white {
  background-color: #fff;
  border: 0px;
  font-weight: 500;
  color: #000;
}

#blue_light {
  background-color: #e5eefdff;
  color: #1654ccff;
  font-weight: 500;
  border: 2px solid #1654ccff;
}

#blue_muda_light {
  background-color: #f1fbff;
  color: #0ca5eb;
  font-weight: 500;
  border: 2px solid #0ca5eb;
}

#blue_outline {
  background-color: rgb(225, 228, 255);
  border: 2px solid #2649ef;
}

#status_active {
  background-color: #1e5e96ff;
  border: 2px solid #1e5e96ff;
  color: #ffffff;
}

#status_non_active {
  background-color: #ffffff;
  border: 2px solid #d8d8d8;
  color: rgb(86, 86, 86);
}

#deleteBright {
  background-color: #ca0b00;
  color: white;
}

#delete_bright {
  background-color: #ca0b00;
  color: white;
}

#editBright {
  background-color: rgb(54, 116, 171);
  color: white;
}

#purple {
  background-color: rgb(111, 0, 148);
  color: white;
}

#green {
  background-color: rgb(125, 194, 61);
  color: white;
}

#green_light {
  background-color: #b8ffb8;
  color: #00a500;
  border: 2px solid #00a500;
}

#yellow_light {
  background-color: #fff494;
  color: #877f3a;
  border: 2px solid #877f3a;
}

#green_dark {
  background-color: rgb(8, 162, 0);
  color: white;
}

#dark_blue {
  background-color: #244aa1;
  color: white;
}

#bright_blue {
  background-color: #b4e1fd;
  color: #2893d7;
}

#black {
  background-color: #000000;
  color: white;
}

#grey {
  background-color: #52606e;
  color: white;
}

#gray_light {
  background-color: #ffffff;
  color: #52606e;
  border: 2px solid #52606e;
}

#grey_light {
  background-color: #cee3e9;
  color: #000000;
  border: 2px solid #000000;
}

#almost_white {
  background-color: #d4e5ea;
  color: #000000;
}

.almost_white {
  background-color: #d4e5ea;
  color: #000000;
}

#white {
  background-color: #fff;
  color: #000;
}

#transparent {
  background-color: transparent;
  color: #778b9f;
}

#blue_disable {
  background-color: #cde5f2;
  color: #778b9f;
}

#blue {
  background-color: #0ca5eb;
  color: white;
}

#list_active {
  background-color: #0ca5ebff;
  color: #fff;
  border-bottom: 2px solid #0ca5ebff;
  border-radius: 10px;
}

#red {
  background-color: #e02c35;
  color: white;
}

#orange {
  background-color: #f38026;
  color: white;
}

#text_red {
  color: #e02c35;
}

#red_light {
  background-color: #f9dadc;
  color: #dc000b;
  border: 2px solid #dc000b;
}

#red_dark {
  background-color: #9f0008;
  color: white;
}

#list_non_active {
  background-color: rgb(255, 255, 255);
  color: #60778e;
  border-bottom: 2px solid #d3dbe1;
}

#bright_clear {
  background-color: rgb(244, 244, 244);
  color: rgb(0, 0, 0);
}

#delete {
  background: #d41616;
  color: #fff;
}

#deleteBold {
  background: #9b2020;
  color: #ffe9e9;
  padding: 0px 10px 0px 10px;
  font-size: 10px;
}

#edit {
  background: #e9f9ff;
  color: #20669b;
}

#selected {
  background-color: #0575e6;
  background-image: linear-gradient(to right, #00de59, #0166cb);
  color: #ffffff;
  border: 2px solid #1654ccff;
}

#comfort {
  background: #e9f9ff;
  color: #20669b;
  margin-top: 10px;
  margin-right: 10px;
}
