#tree_diagram {
  display: inline-block;
  padding: 10px;
}

#tree_diagram * {
  box-sizing: border-box;
}

#tree_diagram .branch_tree_diagram {
  padding: 5px 0 5px 20px;
}

#tree_diagram .branch_tree_diagram:not(:first-child) {
  margin-left: 200px;
}

#tree_diagram .branch_tree_diagram:not(:first-child):after {
  content: "";
  width: 20px;
  border-top: 2px solid #000;
  position: absolute;
  left: 180px;
  top: 50%;
  margin-top: 1px;
}

.entry_tree_diagram {
  position: relative;
  min-height: 60px;
  display: block;
}

.entry_tree_diagram:before {
  content: "";
  height: 100%;
  border-left: 2px solid #000;
  position: absolute;
  left: -20px;
}

.entry_tree_diagram:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

.entry_tree_diagram:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 1px;
  border-radius: 10px 0 0 0;
}

.entry_tree_diagram:after {
  content: "";
  width: 20px;
  transition: border 0.5s;
  border-top: 2px solid #000;
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: 1px;
}

.entry_tree_diagram:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}
.entry_tree_diagram:last-child:after {
  height: 10px;
  border-top: none;
  transition: border 0.5s;
  border-bottom: 2px solid #000;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry_tree_diagram:only-child:after {
  width: 10px;
  height: 0px;
  margin-top: 1px;
  border-radius: 0px;
}

.entry_tree_diagram:only-child:before {
  display: none;
}

.entry_tree_diagram span {
  display: block;
  min-width: 180px;
  padding: 5px 10px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
}
