.badge_circle {
  height: 18px;
  width: 18px;
  border-radius: 2rem;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.badge_circle_small {
  height: 10px;
  width: 10px;
  border-radius: 2rem;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.center_child {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.badge_pill_big {
  display: inline-flex;
  height: 28px;
  padding: 0 11px;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 14px;
}

.badge_pill {
  display: inline-block;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 2rem;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 9px 3px 9px;
}

.badge_pill_small {
  display: inline-block;
  padding: 2px 5px 2px 5px;
  font-size: 9px;
  font-weight: 500;
  border-radius: 2rem;
}

.badge_pill_tight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  min-width: 25px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 2rem;
  font-size: 13px;
  display: inline-block;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
}

.badge_normal {
  font-size: 10px;
  display: inline-block;
  padding: 4px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge_bold {
  font-size: 12px;
  font-weight: bolder;
  display: inline-block;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 11px;
  padding-left: 11px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.button_big {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 11px;
  padding-left: 11px;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.button_language {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
}

.button_language.active {
  background-color: #000;
}

.button_small {
  padding: 3px 7px 3px 7px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

.button_icon {
  padding: 1px 6px 1px 6px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
}

.button_icon_pill {
  display: inline-block;
  padding: 5px 20px 5px 20px;
  border-radius: 2rem;
  margin-right: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.button_circle {
  display: inline-block;
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 3px #afafaf;
  cursor: pointer;
}

.button_circle_inside {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button_pill {
  text-align: center;
  display: inline-block;
  border-radius: 2rem;
  padding: 5px 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.button_pill:active {
  animation: button_confirm 1s forwards;
}

@keyframes button_confirm {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.button_pill_normal {
  text-align: center;
  display: inline-block;
  border-radius: 2rem 2rem 2rem 2rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.button_slider_new {
  cursor: pointer;
  width: 30px;
  height: 15px;
  border-radius: 2rem;
  padding: 3px;
  position: relative;
}

.button_standard {
  padding: 7px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.button_square_image {
  width: 50px;
  height: 50px;
  padding: 7px;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
}

.button_standard_tight {
  padding: 3px 7px 3px 7px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 550;
}

.button_block {
  width: 100%;
  padding: 14px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.button_list {
  padding: 10px;
  margin-bottom: 25px;
  cursor: pointer;
}

.button_block_tight {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  display: inline-block;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.button_pill_tight {
  border-radius: 2rem;
  border: none;
  padding: 2px 10px 2px 10px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.buttonNavBrand {
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 10px;
  margin: 0px;
  padding: 2px 15px 2px 2px;
}

.button_very_small {
  text-align: center;
  display: inline-block;
  border-radius: 5px 5px 5px 5px;
  padding: 3px 6px 3px 6px;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}

.text_small {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  white-space: pre-wrap;
}

.text_medium {
  font-size: 13px;
}

.text_bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.3px;
}

.text_header {
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.7px;
}

.text_big {
  font-size: 30px;
  font-weight: 550;
  line-height: 110%;
  letter-spacing: -0.3px;
}

.text_pricing {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: 555;
  margin-bottom: 5px;
}

.text_feature_pricing {
  font-size: 13px;
  letter-spacing: -0.7px;
  font-weight: 650;
  margin-bottom: 5px;
  line-height: 17px;
}

.text_inferior {
  font-size: 12px;
  font-weight: 500;
}

.text_medium_normal {
  font-size: 13px;
  font-weight: 450;
}

.text_title_thin {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.title_blog {
  font-size: 15px;
  overflow: hidden;
  max-height: 60px;
  line-height: 130%;
}

.title_blog_sini {
  font-size: 15px;
  overflow: hidden;
  max-height: 60px;
  line-height: 130%;
  position: absolute;
  bottom: 30px;
}

.sub_title_blog {
  overflow: hidden;
  max-height: 17px;
}

.sub_title_blog_card {
  overflow: hidden;
  max-height: 17px;
  position: absolute;
  bottom: 10px;
  cursor: text;
}

.blog_sini_sub_title {
  overflow: hidden;
  max-height: 17px;
  position: absolute;
  bottom: 30px;
  cursor: text;
}

.blog_sini_button {
  overflow: hidden;
  max-height: 17px;
  position: absolute;
  bottom: 0px;
}

.title_project {
  margin-top: 10px;
  font-size: 17px;
  overflow: hidden;
  max-height: 5rem;
  line-height: 130%;
  word-break: break-all;
}

.sub_title_project {
  overflow: hidden;
  max-height: 65px;
  word-break: break-all;
}

.text_collapse {
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 0px;
  align-items: center;
  cursor: pointer;
}

.text_collapse_4 {
  display: grid;
  grid-template-columns: 20px auto auto;
  grid-gap: 0px;
  align-items: left;
}

.text_danger {
  color: #cb4747;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

.bold_normal {
  font-size: 21px;
  font-weight: bold;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.badge_small {
  padding: 1px 5px;
  text-align: center;
  display: inline-block;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 500;
  vertical-align: middle;
}

.button_standard_tight_new {
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: 550;
  color: #373a3cff;
  border: 2px solid #d5d7dbff;
  padding: 3px;
  border-radius: 25px;
}

.button_pill_tight_new {
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.button_pill_small {
  border-radius: 2rem;
  padding: 3px 7px 3px 7px;
  font-size: 10px;
  font-weight: 600;
}

.cursor_pointer {
  cursor: pointer;
}

.badge {
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2em;
  text-align: center;
  display: inline-block;
}
