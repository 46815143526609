@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

html {
  -webkit-tap-highlight-color: transparent;
  font-family: "Figtree", sans-serif;
}

div {
  font-family: "Figtree", sans-serif;
}

button {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: "Figtree", sans-serif;
  margin: 0;
}

p {
  margin: 0px;
}

textarea {
  resize: none;
}

* {
  white-space: normal;
  scrollbar-color: #000000 #d3d3d3;
  scrollbar-width: thin;
}

::placeholder {
  color: #9e9e9e;
}

:-ms-input-placeholder {
  color: #cecece;
}

::-ms-input-placeholder {
  color: #cecece;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: #9eb3c0;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
  border: 3px solid #9eb3c0;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #242424;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  box-sizing: border-box;
  background-color: transparent;
  margin: auto;
  text-align: left;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

input {
  width: 100%;
  padding: 17px 7px 17px 7px;
  display: inline-block;
  border: 2px solid #e8ecf1;
  border-radius: 11px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  height: 40px;
  min-height: 40px;
  font-family: "Figtree", sans-serif;
}

input[type="color"] {
  height: 50px;
  min-height: 50px;
  padding: 14px 7px;
  background-color: #fff;
}

.full_width {
  width: 100%;
}

textarea {
  width: 100%;
  padding: 5px 15px 5px 15px;
  display: inline-block;
  border: 2px solid #e8ecf1;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  white-space: pre-wrap;
  color: #515151;
}

select {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
  border: 2px solid #e8ecf1;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: normal;
  color: #515151;
  height: 40px;
  font-family: "Figtree", sans-serif;
}

.select-wrapper {
  position: relative;
}

.remove-select-arrow {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper::after {
  content: "▼";
  font-size: 0.6rem;
  top: 26px;
  right: 20px;
  position: absolute;
}

.radio_button {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 2rem;
  border: 3px solid #e8ecf1;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}

.multi_check {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  margin-right: 10px;
  font-size: 17px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}

.multi_check_v2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uploadFileContainer {
  position: relative;
  cursor: pointer;
}

.white-link {
  overflow: hidden;
}

.white-link a {
  color: white;
}

.fileContainer {
  cursor: pointer;
  width: 100%;
}

.fileContainer [type="file"] {
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: pointer;
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.slider_check:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

input:checked + .slider_check {
  background-color: #0ca5eb;
}

input:focus + .slider_check {
  box-shadow: 0 0 1px #0ca5eb;
}

input:checked + .slider_check:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider_check.round {
  border-radius: 17px;
}

.slider_check.round:before {
  border-radius: 50%;
}
