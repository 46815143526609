/*MAP EDITOR*/
.map_button_control {
  z-index: 3;
  background-color: #fff;
  position: fixed;
  right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease;
}

.map_button_control:active {
  transform: scale(1.2);
}

.create_geometry {
  top: 135px;
}

.delete_geometry {
  top: 170px;
}

.sidebar_right_button_map {
  top: 135px;
  transition: top 195ms ease-out;
}

.legend_map {
  top: 170px;
  transition: top 195ms ease-out;
}

.change_basemap_button_map {
  top: 205px;
  transition: top 195ms ease-out;
}

.change_language_button_map {
  top: 240px;
  transition: top 195ms ease-out;
}

.setting_map {
  top: 275px;
  transition: top 195ms ease-out;
}

.control_active {
  background-color: rgb(133, 231, 145);
}

/*MAP BUSINESS INTELIGENCE*/
.map_button_control_bi {
  position: absolute;
  right: 5px;
  z-index: 3;
  background-color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 500ms ease;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.map_button_control_bi:active {
  transform: scale(1.2);
}

.zoom_in_bi {
  top: 15px;
}

.zoom_out_bi {
  top: 50px;
}

.reset_compass_bi {
  top: 85px;
}

.gps_map_bi {
  top: 120px;
}

.sini_map_bi {
  top: 135px;
}
